/* *, *::before, *::after {
    box-sizing: border-box;
}
 */

.error-hero-404 {

    background: url('/public/compass_lost.jpg') no-repeat top center;
    background-size: cover;
    min-height: 70dvh;

}

.text-effect {

    background: linear-gradient(90deg,red, blue);
    background-clip: text;
    color: transparent;
}